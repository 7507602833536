////////////////////////SCRIPTS////////////////////
////Lancer direct à l'ouverture de l'application////

$( document ).ready(function() {
    console.log( "app.js ready!" );
});

//dashboard homeUser et Admin
function initializeDatatable(){
  if ( $.fn.dataTable.isDataTable( '.tableUser' ) ) {
    var table = $('.tableUser').DataTable();
    //réinitialise le tableau si on retourne dessus
    table.destroy()
    table = $('.tableUser').DataTable({
      order: [[3, 'desc']], //marche pas je sais pas pourquoi
      lengthMenu: [[5,10,25,50,-1], [5,10,25,50,"All"]],
    });
  }
  else { //première arrivée sur un tableau
    table = $('.tableUser').DataTable({
      lengthMenu: [[5,10,25,50,-1], [5,10,25,50,"All"]],
    });
  }

}

// function initializeDatatable(){
//   if ( $.fn.dataTable.isDataTable( '.tableUser' ) ) {
//     var table = $('.tableUser').DataTable();
//     //réinitialise le tableau si on retourne dessus
//     table.destroy();

//     table.clear().draw();
//     table = $('.tableUser').DataTable({
//       order: [[3, 'desc']], //marche pas je sais pas pourquoi
//       lengthMenu: [[5,10,25,50,-1], [5,10,25,50,"All"]],
//     });
//     console.log("DANS LE IF")
//   }
//   else { //première arrivée sur un tableau
//     table = $('.tableUser').DataTable({
//       lengthMenu: [[5,10,25,50,-1], [5,10,25,50,"All"]],
//       stateSave: false
//     });
//     console.log("DANS LE ELSE")


//     $('.tableUser tbody').on('change', function () {
//         var data = table.row(this).data();
//         alert('VALUE ' + data[0] + " has changed");
//     });
//   }

// }

// function initializeDatatable2(demandes){
//   var dataset = [
//     [ "new", "data", "test", ],
//     [ "Nixon", "System", "Edinburgh", ],
//   ];

//     //var data = JSON.parse(demandes)
//     $('.tableUser').DataTable().clear().rows.add(dataset).draw();
// }

function initializeDatatable2(){
  if ( $.fn.dataTable.isDataTable( '.tableUser' ) ) {
    var table = $('.tableUser').DataTable();
    //réinitialise le tableau si on retourne dessus
    table.destroy();



    table = $('.tableUser').DataTable({
      order: [[3, 'desc']], //marche pas je sais pas pourquoi
      lengthMenu: [[5,10,25,50,-1], [5,10,25,50,"All"]],
    });

    table
    .rows()
    .invalidate()
    .draw(false);
    console.log("DANS LE IF")
  }



}

// function initializeDatatable2(){


//     //$('.tableUser').DataTable().destroy();

//     //
//     $('.tableUser').DataTable().rows().invalidate('dom').draw();
//     $('.tableUser').DataTable().cells().invalidate('dom').draw();
//     $('.tableUser').DataTable().draw();
// }

//appelé par toutes les modales de l'application
//permet à la modale d'être mobile et de pouvoir naviguer quand meme
function modalMovable() {
    $('.modal-dialog').draggable({
          handle: '.modal-header'
        });
}

//appelée depuis tableauEvals.ts
      function getColorDesignEval(elem) {
        const designIcon = document.getElementById('designIcon');
        const designText = document.getElementById('designText');

        if (elem == 'DLA is reviewing') {
          designIcon.style.color = 'blue';
          designText.style.color = 'blue';
        }
        if (elem == 'Recommended') {
          designIcon.style.color = 'green';
          designText.style.color = 'green';
        }
        if (elem == 'Reservations') {
          designIcon.style.color = 'orange';
          designText.style.color = 'orange';
        }
        if (elem == 'Not recommended') {
          designIcon.style.color = 'red';
          designText.style.color = 'red';
        }
        if (elem === null) {
          //Completed = y'a pas encore eu d'éval
          designIcon.style.color = '';
        }
      }

      //appelée depuis tableauEvals.ts
      function getColorLegalEval(elem) {
        const legalIcon = document.getElementById('legalIcon');
        const legalText = document.getElementById('legalText');

       if (elem == 'DLA is reviewing') {
          legalIcon.style.color = 'blue';
          legalText.style.color = 'blue';
        }
        if (elem == 'Recommended') {
          legalIcon.style.color = 'green';
          legalText.style.color = 'green';
        }
        if (elem == 'Reservations') {
          legalIcon.style.color = 'orange';
          legalText.style.color = 'orange';
        }
        if (elem == 'Not recommended') {
          legalIcon.style.color = 'red';
          legalText.style.color = 'red';
        }
        if (elem === null) {
          //Completed = y'a pas encore eu d'éval
          legalIcon.style.color = '';
        }
      }

      //appelée depuis tableauEvals.ts
      function getColorArticle32Eval(elem) {
        const article32Icon = document.getElementById('article32Icon');
        const article32Text = document.getElementById('article32Text');

        if (elem == 'DLA is reviewing') {
          article32Icon.style.color = 'blue';
          article32Text.style.color = 'blue';
        }
        if (elem == 'Recommended') {
          article32Icon.style.color = 'green';
          article32Text.style.color = 'green';
        }
        if (elem == 'Reservations') {
          article32Icon.style.color = 'orange';
          article32Text.style.color = 'orange';
        }
        if (elem == 'Not recommended') {
          article32Icon.style.color = 'red';
          article32Text.style.color = 'red';
        }
        if (elem === null) {
          //Completed = y'a pas encore eu d'éval
          article32Icon.style.color = '';
        }
      }

      //appelée depuis tableauEvals.ts
      function getColorArticle28Eval(elem) {
        const article28Icon = document.getElementById('article28Icon');
        const article28Text = document.getElementById('article28Text');

         if (elem == 'DLA is reviewing') {
          article28Icon.style.color = 'blue';
          article28Text.style.color = 'blue';
        }
        if (elem == 'Recommended') {
          article28Icon.style.color = 'green';
          article28Text.style.color = 'green';
        }
        if (elem == 'Reservations') {
          article28Icon.style.color = 'orange';
          article28Text.style.color = 'orange';
        }
        if (elem == 'Not recommended') {
          article28Icon.style.color = 'red';
          article28Text.style.color = 'red';
        }
        if (elem === null) {
          //Completed = y'a pas encore eu d'éval
          article28Icon.style.color = '';
        }
      }

      //appelée depuis tableauEvals.ts
      function getColorIaaSEval(elem) {
        const IaasIcon = document.getElementById('iaasIcon');
        const IaasText = document.getElementById('iaasText');

         if (elem == 'DLA is reviewing') {
          IaasIcon.style.color = 'blue';
          IaasText.style.color = 'blue';
        }
        if (elem == 'Recommended') {
          IaasIcon.style.color = 'green';
          IaasText.style.color = 'green';
        }
        if (elem == 'Reservations') {
          IaasIcon.style.color = 'orange';
          IaasText.style.color = 'orange';
        }
        if (elem == 'Not recommended') {
          IaasIcon.style.color = 'red';
          IaasText.style.color = 'red';
        }
        if (elem === null) {
          //Completed = y'a pas encore eu d'éval
          IaasIcon.style.color = '';
        }
      }

      //Pour afficher un text d'info au survol du form pour évaluer alors que tous les chaps n'ont pas été évalué overview-eval
      function mouseOver() {
        $('div.notComplete').mouseover(function() {
          $(this).css('cursor', 'pointer');
          $('div.overlay').css('visibility', 'visible');
        });
        $('div.notComplete').mouseout(function() {
          $(this).css('cursor', 'default');
          $('div.overlay').css('visibility', 'hidden');
        });
      }

      //Si une solution est en status pending
      function mouseOver2() {
        $('div.pending').mouseover(function() {
          $(this).css('cursor', 'pointer');
          $('div.overlay').css('visibility', 'visible');
        });
        $('div.pending').mouseout(function() {
          $(this).css('cursor', 'default');
          $('div.overlay').css('visibility', 'hidden');
        });
      }

      //Si une solution est en status Canceled
      function mouseOver3() {
        $('div.canceled').mouseover(function() {
          $(this).css('cursor', 'pointer');
          $('div.overlay').css('visibility', 'visible');
        });
        $('div.canceled').mouseout(function() {
          $(this).css('cursor', 'default');
          $('div.overlay').css('visibility', 'hidden');
        });
      }

      //function pour colorer les chapitres pour overview evaluation et summary evaluation
      function getColorsChapters(form) {
        setTimeout(function() {
        const  DA1eval = document.getElementById('DA1eval');
        const  DA2eval = document.getElementById('DA2eval');
        const  DA3eval = document.getElementById('DA3eval');
        const  DA4eval = document.getElementById('DA4eval');
        const  DA5eval = document.getElementById('DA5eval');
        const  DA6eval = document.getElementById('DA6eval');
        const  DA7eval = document.getElementById('DA7eval');
        const  DA8eval = document.getElementById('DA8eval');
        const  DA9eval = document.getElementById('DA9eval');

        const  LA1eval = document.getElementById('LA1eval');
        const  LA2eval = document.getElementById('LA2eval');
        const  LA3eval = document.getElementById('LA3eval');
        const  LA4eval = document.getElementById('LA4eval');
        const  LA5eval = document.getElementById('LA5eval');

        const  AR32eval0 = document.getElementById('AR32eval0');
        const  AR32eval1 = document.getElementById('AR32eval1');
        const  AR32eval2 = document.getElementById('AR32eval2');
        const  AR32eval3 = document.getElementById('AR32eval3');
        const  AR32eval4 = document.getElementById('AR32eval4');
        const  AR32eval5 = document.getElementById('AR32eval5');
        const  AR32eval6 = document.getElementById('AR32eval6');
        const  AR32eval7 = document.getElementById('AR32eval7');
        const  AR32eval8 = document.getElementById('AR32eval8');

        // const  chapSCC1AR28 = document.getElementById('chapSCC1AR28');
        // const  chapSCC1ANNEX1AR28 = document.getElementById('chapSCC1ANNEX1AR28');
        // const  chapSCC1ANNEX2AR28 = document.getElementById('chapSCC1ANNEX2AR28');
        const  chapSCC1ANNEX3AR28 = document.getElementById('AR28_SCC1_ANNEX_3eval');
        // const  chapSCC1ANNEX4AR28 = document.getElementById('chapSCC1ANNEX4AR28');
        // const  chapSCC1ANNEX5AR28 = document.getElementById('chapSCC1ANNEX5AR28');
        // const  chapSCC2AR28 = document.getElementById('chapSCC2AR28');
        // const  chapSCC2ANNEX1AR28 = document.getElementById('chapSCC2ANNEX1AR28');
        // const  chapSCC2ANNEX2AR28 = document.getElementById('chapSCC2ANNEX2AR28');
        // const  chapSCC2ANNEX3AR28 = document.getElementById('chapSCC2ANNEX3AR28');
        // const  chapSCHREMSUSAR28 = document.getElementById('chapSCHREMSUSAR28');
        // const  chapSCHREMSUKAR28 = document.getElementById('chapSCHREMSUKAR28');
        // const  chapSCHREMSINDIAAR28 = document.getElementById('chapSCHREMSINDIAAR28');
        // const  chapSCHREMSGENERICAR28 = document.getElementById('chapSCHREMSGENERICAR28');

        const  IAAS1eval = document.getElementById('IAAS1eval');
        const  IAAS2eval = document.getElementById('IAAS2eval');
        const  IAAS3eval = document.getElementById('IAAS3eval');
        const  IAAS4eval = document.getElementById('IAAS4eval');

          if (form === 'Design Form') {
            //pour ne pas avoir d'erreur TypeError: Cannot set property 'textContent' of null quand y'a eu aucune éval
            if (DA1eval || DA2eval || DA3eval || DA4eval || DA5eval || DA6eval || DA7eval || DA8eval || DA9eval) {

              for (let i = 1; i <= 9; i++) {
                if (document.getElementById('DA'+i+'eval').textContent == 'Recommended') {
                document.getElementById('DA'+i+'titre').style.color = 'green';
                document.getElementById('DA'+i+'eval').style.color = 'green';
                }
                if (document.getElementById('DA'+i+'eval').textContent == 'Recommended with reservations') {
                document.getElementById('DA'+i+'titre').style.color = 'orange';
                document.getElementById('DA'+i+'eval').style.color = 'orange';
                }
                if (document.getElementById('DA'+i+'eval').textContent == 'Not recommended') {
                  document.getElementById('DA'+i+'titre').style.color = 'red';
                  document.getElementById('DA'+i+'eval').style.color = 'red';
                }
                if (document.getElementById('DA'+i+'eval').textContent == 'On hold') {
                  document.getElementById('DA'+i+'titre').style.color = 'gold';
                  document.getElementById('DA'+i+'eval').style.color = 'gold';
                }
              }

            }
          }

          if (form === 'Legal Form') {
            //pour ne pas avoir d'erreur TypeError: Cannot set property 'textContent' of null quand y'a eu aucune éval
            if (LA0eval || LA1eval || LA2eval || LA3eval || LA4eval || LA5eval) {

              for (let i = 0; i <= 5; i++) {
                if (document.getElementById('LA'+i+'eval').textContent == 'Recommended') {
                document.getElementById('LA'+i+'titre').style.color = 'green';
                document.getElementById('LA'+i+'eval').style.color = 'green';
                }
                if (document.getElementById('LA'+i+'eval').textContent == 'Recommended with reservations') {
                document.getElementById('LA'+i+'titre').style.color = 'orange';
                document.getElementById('LA'+i+'eval').style.color = 'orange';
                }
                if (document.getElementById('LA'+i+'eval').textContent == 'Not recommended') {
                  document.getElementById('LA'+i+'titre').style.color = 'red';
                  document.getElementById('LA'+i+'eval').style.color = 'red';
                }
                if (document.getElementById('LA'+i+'eval').textContent == 'On hold') {
                  document.getElementById('LA'+i+'titre').style.color = 'gold';
                  document.getElementById('LA'+i+'eval').style.color = 'gold';
                }
              }

            }
          }

          if (form === 'Article 32 Form') {
            //pour ne pas avoir d'erreur TypeError: Cannot set property 'textContent' of null quand y'a eu aucune éval
            if (AR32eval0 || AR32eval1 || AR32eval2 || AR32eval3 || AR32eval4 || AR32eval5 || AR32eval6 || AR32eval7 || AR32eval8) {

              for (let i = 0; i <= 8; i++) {
                if (document.getElementById('AR32eval'+i).textContent == 'Recommended') {
                document.getElementById('AR32titre'+i).style.color = 'green';
                document.getElementById('AR32eval'+i).style.color = 'green';
                }
                if (document.getElementById('AR32eval'+i).textContent == 'Recommended with reservations') {
                document.getElementById('AR32titre'+i).style.color = 'orange';
                document.getElementById('AR32eval'+i).style.color = 'orange';
                }
                if (document.getElementById('AR32eval'+i).textContent == 'Not recommended') {
                  document.getElementById('AR32titre'+i).style.color = 'red';
                  document.getElementById('AR32eval'+i).style.color = 'red';
                }
                if (document.getElementById('AR32eval'+i).textContent == 'On hold') {
                  document.getElementById('AR32titre'+i).style.color = 'gold';
                  document.getElementById('AR32eval'+i).style.color = 'gold';
                }
              }

            }
          }

          if (form === 'Article 28 Form') {
            //pour ne pas avoir d'erreur TypeError: Cannot set property 'textContent' of null quand y'a eu aucune éval
            // if (chapSCC1AR28 || chapSCC1ANNEX1AR28 || chapSCC1ANNEX2AR28 || chapSCC1ANNEX3AR28 || chapSCC1ANNEX4AR28 || chapSCC1ANNEX5AR28 || chapSCC2AR28 || chapSCC2ANNEX1AR28 || chapSCC2ANNEX2AR28
            // || chapSCC2ANNEX3AR28 || chapSCHREMSUSAR28 || chapSCHREMSUKAR28 || chapSCHREMSINDIAAR28 || chapSCHREMSGENERICAR28) {

              if (chapSCC1ANNEX3AR28) {

            // for(let s = 1; s <= 2; s++){
            //   for(let i = 1; i <= 5; i++) {
                //SCC1 et 2
                // if (document.getElementById(`AR28_SCC${s}eval`).textContent == 'Recommended') {
                // document.getElementById(`AR28_SCC${s}titre`).style.color = 'green';
                // document.getElementById(`AR28_SCC${s}eval`).style.color = 'green';
                // }
                // if (document.getElementById(`AR28_SCC${s}eval`).textContent == 'Recommended with reservations') {
                // document.getElementById(`AR28_SCC${s}titre`).style.color = 'orange';
                // document.getElementById(`AR28_SCC${s}eval`).style.color = 'orange';
                // }
                // if (document.getElementById(`AR28_SCC${s}eval`).textContent == 'Not recommended') {
                // document.getElementById(`AR28_SCC${s}titre`).style.color = 'red';
                // document.getElementById(`AR28_SCC${s}eval`).style.color = 'red';
                // }
                // if (document.getElementById(`AR28_SCC${s}eval`).textContent == 'On hold') {
                // document.getElementById(`AR28_SCC${s}titre`).style.color = 'gold';
                // document.getElementById(`AR28_SCC${s}eval`).style.color = 'gold';
                // }
                //ANNEX
                // if (document.getElementById(`AR28_SCC${s}_ANNEX_${i}eval`).textContent == 'Recommended') {
                // document.getElementById(`AR28_SCC${s}_ANNEX_${i}titre`).style.color = 'green';
                // document.getElementById(`AR28_SCC${s}_ANNEX_${i}eval`).style.color = 'green';
                // }
                // if (document.getElementById(`AR28_SCC${s}_ANNEX_${i}eval`).textContent == 'Recommended with reservations') {
                // document.getElementById(`AR28_SCC${s}_ANNEX_${i}titre`).style.color = 'orange';
                // document.getElementById(`AR28_SCC${s}_ANNEX_${i}eval`).style.color = 'orange';
                // }
                // if (document.getElementById(`AR28_SCC${s}_ANNEX_${i}eval`).textContent == 'Not recommended') {
                //   document.getElementById(`AR28_SCC${s}_ANNEX_${i}titre`).style.color = 'red';
                //   document.getElementById(`AR28_SCC${s}_ANNEX_${i}eval`).style.color = 'red';
                // }
                // if (document.getElementById(`AR28_SCC${s}_ANNEX_${i}eval`).textContent == 'On hold') {
                //   document.getElementById(`AR28_SCC${s}_ANNEX_${i}titre`).style.color = 'gold';
                //   document.getElementById(`AR28_SCC${s}_ANNEX_${i}eval`).style.color = 'gold';
                // }

                //ANNEX A - TOMs Template
                if (document.getElementById(`AR28_SCC1_ANNEX_3eval`).textContent == 'Recommended') {
                document.getElementById(`AR28_SCC1_ANNEX_3titre`).style.color = 'green';
                document.getElementById(`AR28_SCC1_ANNEX_3eval`).style.color = 'green';
                }
                if (document.getElementById(`AR28_SCC1_ANNEX_3eval`).textContent == 'Recommended with reservations') {
                document.getElementById(`AR28_SCC1_ANNEX_3titre`).style.color = 'orange';
                document.getElementById(`AR28_SCC1_ANNEX_3eval`).style.color = 'orange';
                }
                if (document.getElementById(`AR28_SCC1_ANNEX_3eval`).textContent == 'Not recommended') {
                  document.getElementById(`AR28_SCC1_ANNEX_3titre`).style.color = 'red';
                  document.getElementById(`AR28_SCC1_ANNEX_3eval`).style.color = 'red';
                }
                if (document.getElementById(`AR28_SCC1_ANNEX_3eval`).textContent == 'On hold') {
                  document.getElementById(`AR28_SCC1_ANNEX_3titre`).style.color = 'gold';
                  document.getElementById(`AR28_SCC1_ANNEX_3eval`).style.color = 'gold';
                }

            //   }
            // }

            }
          }

          if (form === 'IaaS Form') {
            //pour ne pas avoir d'erreur TypeError: Cannot set property 'textContent' of null quand y'a eu aucune éval
            if (IAAS1eval || IAAS2eval || IAAS3eval || IAAS4eval) {

              for (let i = 1; i <= 4; i++) {
                if (document.getElementById('IAAS'+i+'eval').textContent == 'Recommended') {
                document.getElementById('IAAS'+i+'titre').style.color = 'green';
                document.getElementById('IAAS'+i+'eval').style.color = 'green';
                }
                if (document.getElementById('IAAS'+i+'eval').textContent == 'Recommended with reservations') {
                document.getElementById('IAAS'+i+'titre').style.color = 'orange';
                document.getElementById('IAAS'+i+'eval').style.color = 'orange';
                }
                if (document.getElementById('IAAS'+i+'eval').textContent == 'Not recommended') {
                  document.getElementById('IAAS'+i+'titre').style.color = 'red';
                  document.getElementById('IAAS'+i+'eval').style.color = 'red';
                }
                if (document.getElementById('IAAS'+i+'eval').textContent == 'On hold') {
                  document.getElementById('IAAS'+i+'titre').style.color = 'gold';
                  document.getElementById('IAAS'+i+'eval').style.color = 'gold';
                }
              }

            }
          }
        }, 1000);
      }


      //function pour colorer les chapitres en fonction du radio pour évaluation
      //Lors de l'ouverture de la modale de l'évaluation des chapitres
      function getColors() {
        setTimeout(function() {
          //DESIGN
          for (let i = 1; i <= 9; i++) {
            if(document.querySelector('input[name="DA_'+i+'_RADIO"]:checked')) {

              if(document.querySelector('input[name="DA_'+i+'_RADIO"]:checked').value == 'Recommended') {
                document.getElementById('chap'+i+'DA').style.color = 'green';
              }
              if(document.querySelector('input[name="DA_'+i+'_RADIO"]:checked').value == 'Recommended with reservations') {
                document.getElementById('chap'+i+'DA').style.color = 'orange';
              }
              if(document.querySelector('input[name="DA_'+i+'_RADIO"]:checked').value == 'Not recommended') {
                document.getElementById('chap'+i+'DA').style.color = 'red';
              }
              if(document.querySelector('input[name="DA_'+i+'_RADIO"]:checked').value == 'On hold') {
                document.getElementById('chap'+i+'DA').style.color = 'gold';
              }

            }

            //au changement du radio directement
            $('input:radio[name="DA_'+i+'_RADIO"]').change(' input:radio[name="DA_'+i+'_RADIO"]', function() {
              if ($(this).is(':checked') && $(this).val() == 'Recommended') {
                document.getElementById('chap'+i+'DA').style.color = 'green';
              }
              if ($(this).is(':checked') && $(this).val() == 'Recommended with reservations') {
                document.getElementById('chap'+i+'DA').style.color = 'orange';
              }
              if ($(this).is(':checked') && $(this).val() == 'Not recommended') {
                document.getElementById('chap'+i+'DA').style.color = 'red';
              }
              if ($(this).is(':checked') && $(this).val() == 'On hold') {
                document.getElementById('chap'+i+'DA').style.color = 'gold';
              }
            });

          }

          //LEGAL
          for (let i = 0; i <= 5; i++) {
            if(document.querySelector('input[name="LA_'+i+'_RADIO"]:checked')) {

              if(document.querySelector('input[name="LA_'+i+'_RADIO"]:checked').value == 'Recommended') {
                document.getElementById('chap'+i+'LA').style.color = 'green';
              }
              if(document.querySelector('input[name="LA_'+i+'_RADIO"]:checked').value == 'Recommended with reservations') {
                document.getElementById('chap'+i+'LA').style.color = 'orange';
              }
              if(document.querySelector('input[name="LA_'+i+'_RADIO"]:checked').value == 'Not recommended') {
                document.getElementById('chap'+i+'LA').style.color = 'red';
              }
              if(document.querySelector('input[name="LA_'+i+'_RADIO"]:checked').value == 'On hold') {
                document.getElementById('chap'+i+'LA').style.color = 'gold';
              }

            }

            //au changement du radio directement
            $('input:radio[name="LA_'+i+'_RADIO"]').change(' input:radio[name="LA_'+i+'_RADIO"]', function() {
              if ($(this).is(':checked') && $(this).val() == 'Recommended') {
                document.getElementById('chap'+i+'LA').style.color = 'green';
              }
              if ($(this).is(':checked') && $(this).val() == 'Recommended with reservations') {
                document.getElementById('chap'+i+'LA').style.color = 'orange';
              }
              if ($(this).is(':checked') && $(this).val() == 'Not recommended') {
                document.getElementById('chap'+i+'LA').style.color = 'red';
              }
              if ($(this).is(':checked') && $(this).val() == 'On hold') {
                document.getElementById('chap'+i+'LA').style.color = 'gold';
              }
            });

          }

          //ARTICLE 32
          for (let i = 0; i <= 8; i++) {
            if(document.querySelector('input[name="AR32_'+i+'_RADIO"]:checked')) {

              if(document.querySelector('input[name="AR32_'+i+'_RADIO"]:checked').value == 'Recommended') {
                document.getElementById('chap'+i+'AR32').style.color = 'green';
              }
              if(document.querySelector('input[name="AR32_'+i+'_RADIO"]:checked').value == 'Recommended with reservations') {
                document.getElementById('chap'+i+'AR32').style.color = 'orange';
              }
              if(document.querySelector('input[name="AR32_'+i+'_RADIO"]:checked').value == 'Not recommended') {
                document.getElementById('chap'+i+'AR32').style.color = 'red';
              }
              if(document.querySelector('input[name="AR32_'+i+'_RADIO"]:checked').value == 'On hold') {
                document.getElementById('chap'+i+'AR32').style.color = 'gold';
              }

            }

            //au changement du radio directement
            $('input:radio[name="AR32_'+i+'_RADIO"]').change(' input:radio[name="AR32_'+i+'_RADIO"]', function() {
              if ($(this).is(':checked') && $(this).val() == 'Recommended') {
                document.getElementById('chap'+i+'AR32').style.color = 'green';
              }
              if ($(this).is(':checked') && $(this).val() == 'Recommended with reservations') {
                document.getElementById('chap'+i+'AR32').style.color = 'orange';
              }
              if ($(this).is(':checked') && $(this).val() == 'Not recommended') {
                document.getElementById('chap'+i+'AR32').style.color = 'red';
              }
              if ($(this).is(':checked') && $(this).val() == 'On hold') {
                document.getElementById('chap'+i+'AR32').style.color = 'gold';
              }
            });

          }

          //ARTICLE 28
          for(let s = 1; s <= 2; s++){
              for(let i = 1; i <= 5; i++) {
            //SCC1 et SCC2
            // if(document.querySelector('input[name="AR28_SCC'+s+'_RADIO"]:checked')) {

            //   if(document.querySelector('input[name="AR28_SCC'+s+'_RADIO"]:checked').value == 'Recommended') {
            //     document.getElementById('chapSCC'+s+'AR28').style.color = 'green';
            //   }
            //   if(document.querySelector('input[name="AR28_SCC'+s+'_RADIO"]:checked').value == 'Recommended with reservations') {
            //     document.getElementById('chapSCC'+s+'AR28').style.color = 'orange';
            //   }
            //   if(document.querySelector('input[name="AR28_SCC'+s+'_RADIO"]:checked').value == 'Not recommended') {
            //     document.getElementById('chapSCC'+s+'AR28').style.color = 'red';
            //   }
            //   if(document.querySelector('input[name="AR28_SCC'+s+'_RADIO"]:checked').value == 'On hold') {
            //     document.getElementById('chapSCC'+s+'AR28').style.color = 'gold';
            //   }

            // }

            // //au changement du radio directement
            // $('input:radio[name="AR28_SCC'+s+'_RADIO"]').change(' input:radio[name="AR28_SCC'+s+'_RADIO"]', function() {
            //   if ($(this).is(':checked') && $(this).val() == 'Recommended') {
            //     document.getElementById('chapSCC'+s+'AR28').style.color = 'green';
            //   }
            //   if ($(this).is(':checked') && $(this).val() == 'Recommended with reservations') {
            //     document.getElementById('chapSCC'+s+'AR28').style.color = 'orange';
            //   }
            //   if ($(this).is(':checked') && $(this).val() == 'Not recommended') {
            //     document.getElementById('chapSCC'+s+'AR28').style.color = 'red';
            //   }
            //   if ($(this).is(':checked') && $(this).val() == 'On hold') {
            //     document.getElementById('chapSCC'+s+'AR28').style.color = 'gold';
            //   }
            // });

            //ANNEX
            if(document.querySelector('input[name="AR28_SCC'+s+'_ANNEX_'+i+'_RADIO"]:checked')) {

              if(document.querySelector('input[name="AR28_SCC'+s+'_ANNEX_'+i+'_RADIO"]:checked').value == 'Recommended') {
                document.getElementById('chapSCC'+s+'ANNEX'+i+'AR28').style.color = 'green';
              }
              if(document.querySelector('input[name="AR28_SCC'+i+'_ANNEX_'+i+'_RADIO"]:checked').value == 'Recommended with reservations') {
                document.getElementById('chapSCC'+s+'ANNEX'+i+'AR28').style.color = 'orange';
              }
              if(document.querySelector('input[name="AR28_SCC'+i+'_ANNEX_'+i+'_RADIO"]:checked').value == 'Not recommended') {
                document.getElementById('chapSCC'+s+'ANNEX'+i+'AR28').style.color = 'red';
              }
              if(document.querySelector('input[name="AR28_SCC'+i+'_ANNEX_'+i+'_RADIO"]:checked').value == 'On hold') {
                document.getElementById('chapSCC'+s+'ANNEX'+i+'AR28').style.color = 'gold';
              }

            }

            //au changement du radio directement
            $('input:radio[name="AR28_SCC'+s+'_ANNEX_'+i+'_RADIO"]').change(' input:radio[name="AR28_SCC'+s+'_ANNEX_'+i+'_RADIO"]', function() {
              if ($(this).is(':checked') && $(this).val() == 'Recommended') {
                document.getElementById('chapSCC'+s+'ANNEX'+i+'AR28').style.color = 'green';
              }
              if ($(this).is(':checked') && $(this).val() == 'Recommended with reservations') {
                document.getElementById('chapSCC'+s+'ANNEX'+i+'AR28').style.color = 'orange';
              }
              if ($(this).is(':checked') && $(this).val() == 'Not recommended') {
                document.getElementById('chapSCC'+s+'ANNEX'+i+'AR28').style.color = 'red';
              }
              if ($(this).is(':checked') && $(this).val() == 'On hold') {
                document.getElementById('chapSCC'+s+'ANNEX'+i+'AR28').style.color = 'gold';
              }
            });

          }

          }

          //IAAS
          for (let i = 1; i <= 4; i++) {
            if(document.querySelector('input[name="IAAS_'+i+'_RADIO"]:checked')) {

              if(document.querySelector('input[name="IAAS_'+i+'_RADIO"]:checked').value == 'Recommended') {
                document.getElementById('chap'+i+'IAAS').style.color = 'green';
              }
              if(document.querySelector('input[name="IAAS_'+i+'_RADIO"]:checked').value == 'Recommended with reservations') {
                document.getElementById('chap'+i+'IAAS').style.color = 'orange';
              }
              if(document.querySelector('input[name="IAAS_'+i+'_RADIO"]:checked').value == 'Not recommended') {
                document.getElementById('chap'+i+'IAAS').style.color = 'red';
              }
              if(document.querySelector('input[name="IAAS_'+i+'_RADIO"]:checked').value == 'On hold') {
                document.getElementById('chap'+i+'IAAS').style.color = 'gold';
              }

            }

            //au changement du radio directement
            $('input:radio[name="IAAS_'+i+'_RADIO"]').change(' input:radio[name="IAAS_'+i+'_RADIO"]', function() {
              if ($(this).is(':checked') && $(this).val() == 'Recommended') {
                document.getElementById('chap'+i+'IAAS').style.color = 'green';
              }
              if ($(this).is(':checked') && $(this).val() == 'Recommended with reservations') {
                document.getElementById('chap'+i+'IAAS').style.color = 'orange';
              }
              if ($(this).is(':checked') && $(this).val() == 'Not recommended') {
                document.getElementById('chap'+i+'IAAS').style.color = 'red';
              }
              if ($(this).is(':checked') && $(this).val() == 'On hold') {
                document.getElementById('chap'+i+'IAAS').style.color = 'gold';
              }
            });

          }

        }, 1000);
      }


    //Dans rerservations-list.html
    //ferme les details qui ne sont pas cliqués
    function closeDetailsRes() {
      $("details").on("click", function () {
        $("details[open]").not(this).removeAttr("open");
      });
    }

    //ferme tous les Details lors du clic pour générer le PDF
    function closeAllDetailsRes() {
        $("details[open]").removeAttr("open");
    }

    //Dans all-comments-list.html
    //ferme les details qui ne sont pas cliqués
    function closeDetailsCom() {
      $(".detailsCom").on("click", function () {
        $(".detailsCom[open]").not(this).removeAttr("open");
      });
    }

    //ferme tous les Details lors du clic pour générer le PDF
    function closeAllDetailsCom() {
        $(".detailsCom[open]").removeAttr("open");
    }

    //Dans all-files-list.html
    //ferme les details qui ne sont pas cliqués
    function closeDetailsFile() {
      $(".detailsFile").on("click", function () {
        $(".detailsFile[open]").not(this).removeAttr("open");
      });
    }

    //ferme tous les Details lors du clic pour générer le PDF
    function closeAllDetailsFile() {
        $(".detailsFile[open]").removeAttr("open");
    }

    //Dans logs.html
    //ferme les details qui ne sont pas cliqués
    function closeDetailsLogs() {
      $(".detailsLogs").on("click", function () {
        $(".detailsLogs[open]").not(this).removeAttr("open");
      });
    }

    //ferme tous les Details lors du clic pour générer le PDF
    function closeAllDetailsLogs() {
        $(".detailsLogs[open]").removeAttr("open");
    }

      //utilisé dans le header pour mot de passe oublié lors du bouton mdp oublié cliqué header
      function openForgotMdp() {
        document.getElementById('bodyModal').style.display = 'none';
        document.getElementById('modalHeader1').style.display = 'none';
        document.getElementById('modalHeader2').style.display = 'block';
        document.getElementById('bodyModal2').style.display = 'block';
      }

      //Lorsque la modale est fermée on restaure dans header
      function restoreModal() {
        document.getElementById('bodyModal').style.display = 'block';
        document.getElementById('modalHeader1').style.display = 'block';
        document.getElementById('bodyModal2').style.display = 'none';
        document.getElementById('modalHeader2').style.display = 'none';
        document.getElementById('exampleModal').style.display = 'none';
        $('#loginForm')[0].reset();
        $('#resetPassForm')[0].reset();
      }

      //fermeture de la modale change password dans shell
      function closeModal() {
        document.getElementById('mdpheader').style.display = 'none';
        document.getElementById('mdpbody').style.display = 'none';
        document.getElementById('modalPassword').style.display = 'none';

        $('#resetPassForm')[0].reset();
      }

      function closeModaleRoles() {
        $('#modalRoles').modal('hide');
        $('bodyModal').removeClass('modal-open');

        $('.modal-backdrop').remove(); //supprime le bug de page figé
      }

      //ouverture de la modale changer mdp dans le header
      function openChangeMdp() {
        document.getElementById('modalPassword').style.display = 'block';
        document.getElementById('mdpheader').style.display = 'block';
        document.getElementById('mdpbody').style.display = 'block';
      }

      //Quand on valide le formulaire modifier le mdp on vide les champs et on ferme la modale
      // function submitChangePwd() {
      //   document.getElementById('modalPassword').style.display = 'none';
      //   $('#changePasswordForm')[0].reset();
      // }

      function openLogin() {
        document.getElementById('exampleModal').style.display = 'block';
      }

      function resetDisplayLog() {
        const section = document.getElementById('logSection');
        if (section) {
          section.style.display = 'none';
        }
      }

      //utilisé dans auth.service
      //Si connexion réussi apres login modale ou mdp oublié vendor + new pass faut fermer la modale entièrement car le submit il ferme pas la modale en fait elle est juste chachée
      function closeLoginAndForgotPassAndNewPass() {
        $('#exampleModal').modal('hide'); //connexion + mdp oublié
        $('bodyModal').removeClass('modal-open'); //ferme connexion

        $('bodyModal2').removeClass('modal-open'); //ferme mdp oublié

        $('#modalPassword').modal('hide'); //new mdp
        $('mdpbody').removeClass('modal-open'); //ferme new mdp

        $('.modal-backdrop').remove(); //supprime le bug de page figé
      }

       //fermer la fenetre du completeChapterSubchapter au submit
      function closeCompleteChapterModale() {
        $('#modalComplete').modal('hide');
        $('bodyModal').removeClass('modal-open');
      }

      //utilisé aussi dans la modale contact-form
      function closeModaleHelpContact() {
        $('#modalContact').modal('hide');
        $('#bodyOverview').removeClass('modal-open');
      }


      //Afficher le titre du chapitre dans la modale cliquée
      function getTitleModale(string_length) {
        document.getElementById('titleModaleCom').innerHTML = string_length;
        document.getElementById('titleModaleFile').innerHTML = string_length;
        document.getElementById('titleModaleComplete').innerHTML = string_length;
      }

      //Remettre à 0 l'upload une fois fait, appelé dans le service de formulaireALL
      function resetFormUpload() {
        document.getElementById('formUpload').value = '';
      }


    function setReadOnlyForVendor() {
      //input = les datepicker le champ texte
      //textarea = tous les champs textes
      $('.readonlyVendor input, .readonlyVendor textarea').each(
        function(index){
          const input = $(this);
          input.prop('readonly', true);
        }
      );
    }

    function setReadOnlyForVeolia() {
      //input = les datepicker le champ texte
      //textarea = tous les champs textes
      $('.readonlyVeolia input, .readonlyVeolia textarea').each(
        function(index){
          const input = $(this);
          input.prop('readonly', true);
        }
      );
    }


    //////colonnes tab1//////
   function displayColumnsTab1() {
    let columnsToDisplay = document.getElementsByClassName('moreTab1');
        for(let value of columnsToDisplay)
          {
            value.style.display = 'table-cell';
          }
      document.getElementById('btnDisplayColumnsTab1').style.display = 'none';
      document.getElementById('btnHideColumnsTab1').style.display = 'block';
   }

   function hideColumnsTab1() {
    let columnsToDisplay = document.getElementsByClassName('moreTab1');
        for(let value of columnsToDisplay)
          {
            value.style.display = 'none';
          }
      document.getElementById('btnDisplayColumnsTab1').style.display = 'block';
      document.getElementById('btnHideColumnsTab1').style.display = 'none';
   }
    //////END colonnes tab1//////


     //////colonnes tab2//////
   function displayColumnsTab2() {
    let columnsToDisplay = document.getElementsByClassName('moreTab2');
        for(let value of columnsToDisplay)
          {
            value.style.display = 'table-cell';
          }
      document.getElementById('btnDisplayColumnsTab2').style.display = 'none';
      document.getElementById('btnHideColumnsTab2').style.display = 'block';
   }

   function hideColumnsTab2() {
    let columnsToDisplay = document.getElementsByClassName('moreTab2');
        for(let value of columnsToDisplay)
          {
            value.style.display = 'none';
          }
      document.getElementById('btnDisplayColumnsTab2').style.display = 'block';
      document.getElementById('btnHideColumnsTab2').style.display = 'none';
   }
    //////END colonnes tab2//////

    //////colonnes tab3//////
   function displayColumnsTab3() {
    let columnsToDisplay = document.getElementsByClassName('moreTab3');
        for(let value of columnsToDisplay)
          {
            value.style.display = 'table-cell';
          }
      document.getElementById('btnDisplayColumnsTab3').style.display = 'none';
      document.getElementById('btnHideColumnsTab3').style.display = 'block';
   }

   function hideColumnsTab3() {
    let columnsToDisplay = document.getElementsByClassName('moreTab3');
        for(let value of columnsToDisplay)
          {
            value.style.display = 'none';
          }
      document.getElementById('btnDisplayColumnsTab3').style.display = 'block';
      document.getElementById('btnHideColumnsTab3').style.display = 'none';
   }
    //////END colonnes tab3//////
